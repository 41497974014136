import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import '../styles/contact-us.scss';



const Contact = () => {


    return (
        <Layout
            SEOTitle={"Contact Us - ICE"}
            SEODescription={"Do you need to contact us? ICE is based in St Helier on the Channel Island of Jersey, you can find us here: IFC5, St Helier, Jersey, JE1 1ST. For any accounts related enquiries please send an email to accounts@icecomparison.com."}
            footerDisclaimer={""}>

            <div>
                <div className="contact-column-container">
                    <div className="title-content"  uk-scrollspy="cls: uk-animation-slide-left-small; target: .title; delay: 500;">
                        <h2 className="title">Contact Us</h2>
                    </div>

                    <div className="columns-container">
                        <div className="left-column">
                            <div className="content">
                                <h3>Do you need to contact us?</h3>
                                <p> ICE is based in St Helier on the Channel Island of Jersey, you can find us here:</p>
                                <p><b>IFC5, St Helier, Jersey, JE1 1ST</b></p>
                            </div>

                            <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.0380857100732!2d-2.1135096389920145!3d49.18315148336064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480dadc85373729f%3A0xc5778921a05df834!2sSt%20Helier%2C%20Jersey%20JE1%201ST%2C%20Jersey!5e0!3m2!1sen!2sro!4v1711026785038!5m2!1sen!2sro" width="450" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div className="right-column">
                            <div className="box colored-box" style={{ background: "#e9f6fe", padding: "10px" }}>
                                <p className="subtitle">Telephone: <a href="tel:01534712900">01435 712 900</a></p>
                                <p className="subtitle">Email: <a href="mailto:accounts@icecomparison.com">accounts@icecomparison.com</a></p>
                            </div>
                            <div className="box space-box">
                                <p className="subtitle">Accounts and General Enquiries</p>
                                <p className="description">For any accounts related enquiries please send an email to <a href="mailto:accounts@icecomparison.com">accounts@icecomparison.com</a></p>
                            </div>
                            <div className="box space-box">
                                <p className="subtitle">Affiliates Enquiries</p>
                                <p className="description"><b>Do you want to become part of our affiliate programme?</b></p>
                                <p className="description">If you own a relevant website or company, you could earn a commission from referring your customers to one of our sites. Contact <a href="mailto:accounts@icecomparison.com">accounts@icecomparison.com</a> for more information.</p>
                            </div>
                            <div className="box space-box">
                                <p className="subtitle">Customer Services</p>
                                <p className="description">We want to hear from you if you have any comments or experiences that can help us to improve our service. Please contact Ed directly on <a href="mailto:ed.bevis@icecomparison.com">ed.bevis@icecomparison.com</a>.</p>
                            </div>
                            <div className="box space-box">
                                <p className="subtitle">Marketing & Press Enquiries</p>
                                <p className="description">For any marketing or press enquiries please send an email to Ed at <a href="mailto:ed.bevis@icecomparison.com">ed.bevis@icecomparison.com</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )

}

export default Contact